import * as React from 'react';
import { Navigate } from 'react-router-dom';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import "../Amenities.css"

import BackgroundImage from '../components/Cityline';
import Fab from '@mui/material/Fab';
import ReportIcon from '@mui/icons-material/Report';

import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

import axios from 'axios';
import AppConfig from '../services/app-config';
import { CardHeader } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import FeedbackIcon from '@mui/icons-material/Feedback';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import PersonIcon from '@mui/icons-material/Person';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

import EventBusyIcon from '@mui/icons-material/EventBusy';
import appConfig from '../services/app-config';
import { useSwipeable } from 'react-swipeable';
import { motion, AnimatePresence } from 'framer-motion';


export default function IssueReport({setNavbarState, navbarState}) {
  const [redirect, setRedirect] = React.useState({ trigger: false, route: '/' });
  const [loading, setLoading] = React.useState(true);
  const [activeIssues, setActiveIssues] = React.useState([]);
  const [resolvedIssues, setRresolvedIssues] = React.useState([]);
  const [showBackdrop, setShowBackdrop] = React.useState(false);
  const [tabValue, setTabValue] = React.useState('active');
  const [snackbar, setSnackbar] = React.useState({ open: false, message: '', severity: 'success' });
  const apiUrl = process.env.REACT_APP_MGO_API_URL;
  
  let snackbarmsg = new URLSearchParams(location.search).get('msg');
  let snackbarlvl = new URLSearchParams(location.search).get('lvl',"success");
  let tab = new URLSearchParams(location.search).get('tab');

  const userCors = process.env.REACT_APP_USE_CORS;
  if ( userCors === "true") {
    axios.defaults.withCredentials = true
  }

  const handleTabChange = (event,newValue) => {
    setTabValue(newValue);
    fetchInfo(newValue);
  }

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  const fabStyle = {
    position: 'fixed',
    bottom: 32,
    right: 32
  };

  const stateFriendlyNames = AppConfig.getLocal("issues")

  const fetchInfo = (CurrentTabValue) => {
    setShowBackdrop(true);
    setLoading(true);
    
    const dayOfWeek = AppConfig.getDayOfWeek();
    axios.get(`${apiUrl}/issues/${CurrentTabValue}/list`)
      .then(response => {
        AppConfig.parseResponse(response);
        if (response.status === 200) {
          if (CurrentTabValue === 'active') {
          const dataArray = Object.entries(response.data.data).map(([id, value]) => {
            const estados = value.estados;
            const lastEstado = estados[estados.length - 1];
            return { id, ...value, estado: lastEstado };
          });
          setActiveIssues(dataArray.reverse());
          if (snackbarmsg !== null) {
            setSnackbar({ open: true, message: snackbarmsg, severity: snackbarlvl });
            setRedirect({ trigger: true, route: '/amenidades' });
            snackbarmsg = null;
          }
          }
          else if (CurrentTabValue === 'resolved') {
            const dataArray = Object.entries(response.data.data).map(([id, value]) => {
              const estados = value.estados;
              const lastEstado = estados[estados.length - 1];
              return { id, ...value, estado: lastEstado };
            });
            setRresolvedIssues(dataArray.reverse());
          }
          setShowBackdrop(false);
          setLoading(false);
        }
      })
      .catch(error => {
        console.error('Error fetching issues:', error);
        if (error.response && error.response.data && error.response.data.code === 401) {
          setRedirect({ trigger: true, route: '/login' })
        }
      });
  }

  React.useEffect(() => {
    setNavbarState({...navbarState, showBack: true, showOptions: false, showSearch: false})
    if (tab !== null) { 
      setRedirect({ trigger: true, route: '/inicio' });
      setTabValue(tab);
    }
    fetchInfo(tabValue);
  }, [tabValue]);

  const [swipeDirection, setSwipeDirection] = React.useState(null);

  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => {
      if (tabValue === 'active') {
        setSwipeDirection('left');
        setTabValue('resolved');
      }
    },
    onSwipedRight: () => {
      if (tabValue === 'resolved') {
        setSwipeDirection('right');
        setTabValue('active');
      }
    },
    preventDefaultTouchmoveEvent: true,
    trackMouse: true
  });

  const pageVariants = {
    enter: (direction) => {
      return {
        x: direction === 'left' ? 1000 : -1000,
        opacity: 0
      };
    },
    center: {
      x: 0,
      opacity: 1
    },
    exit: (direction) => {
      return {
        x: direction === 'left' ? -1000 : 1000,
        opacity: 0
      };
    }
  };

  const pageTransition = {
    type: 'tween',
    ease: 'anticipate',
    duration: 0.5
  };

return (
    <Container maxWidth="sm" {...swipeHandlers}>
      {redirect.trigger && (
        <Navigate to={redirect.route} replace={true} />
      )}
    <Box sx={{ mt: 1 }}>
      <Snackbar open={snackbar.open} autoHideDuration={8000} anchorOrigin={{ vertical: "top", horizontal: "center" }} sx={{mt: "3.2rem"}} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
      <Backdrop open={showBackdrop} sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, color: '#fff' }}>
        <CircularProgress color="inherit" />
      </Backdrop>
          <Tabs value={tabValue } onChange={handleTabChange} aria-label="user tabs" variant="fullWidth" sx={{ mt: 0, mb: 0.2 }} >
            <Tab label="Activos" value={'active'}/>
            <Tab label="Resueltos" value={'resolved'}/>
          </Tabs>
        <AnimatePresence initial={false} custom={swipeDirection}>
          <motion.div
            key={tabValue}
            custom={swipeDirection}
            variants={pageVariants}
            initial="enter"
            animate="center"
            exit="exit"
            transition={pageTransition}
          >
            {tabValue === "resolved" ? (
              <>
                {!loading && (
                  resolvedIssues.length === 0 ? (
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 'calc(100vh - 150px)' }}>
                    <Card variant="outlined" sx={{ border: 0, backgroundColor: 'transparent' }} style={{ borderRadius: '10px', textAlign: 'center', width: '100%', paddingTop: '30%', paddingBottom: '40%' }}>
                    <CardContent>
                      <FeedbackIcon sx={{ fontSize: 80, color: 'text.secondary', mb: 2 }} />
                      <Typography variant="h5" component="div" sx={{ mb: 2 }}><b>No hay ningun reporte resuelto</b></Typography>
                      <Divider />
                      <Typography variant="body1" color="text.secondary" sx={{ mt: 2 }}>Aqui podras ver los reportes que esten resueltos.</Typography>
                    </CardContent>
                    </Card>
                  </Box>
                )
                :
                (
                  resolvedIssues.map((issue) => (
                    <Card
                      key={issue.id}
                      variant="outlined"
                      sx={{ mb: 1.2 }}
                      onClick={() => setRedirect({ trigger: true, route: `/reporte?id=${issue.id}` })}
                    >
                      <CardHeader
                        className="card-header"
                        style={{
                          // backgroundImage: `url(${amenity.imagen})`,
                          backgroundImage: `url(${apiUrl}/img/issue/${issue.id}.jpg)`,
                        }}
                      />
                      <CardContent sx={{ pb: 0 }}>
                      <Typography variant="body1" color="text.secondary" sx={{ textAlign: 'right', mt: -1.5}}>
                        Ticket {issue.id.toUpperCase()}
                      </Typography>
                        <Typography variant="h5" component="div">
                          <b>{issue.titulo}</b>
                        </Typography>
                        <Typography variant="body1" color="text.primary" sx={{ pb: 1 }}>
                          {issue.descripcion.length > 80 ? `${issue.descripcion.substring(0, 80)}...` : issue.descripcion}
                        </Typography>
                        <Typography variant="body1" color="text.secondary">
                          <ArrowCircleRightIcon style={{ fontSize: '1rem', verticalAlign: 'middle' }} /> <b>Estado:</b> {stateFriendlyNames[issue.estado.estado].nombre_largo}
                        </Typography>
                        <Typography variant="body1" color="text.secondary">
                          <PersonIcon style={{ fontSize: '1rem', verticalAlign: 'middle' }} /> <b>Reportado por:</b> {appConfig.titleCase(issue.creador.usuario_nombre)}
                        </Typography>
                        <Typography variant="body1" color="text.secondary">
                          <AccessTimeIcon style={{ fontSize: '1rem', verticalAlign: 'middle' }} /> <b>{stateFriendlyNames[issue.estado.estado].nombre_accion} {appConfig.formatFriendlyDate(issue.estado.fecha)}</b>
                        </Typography>
                      </CardContent>
                      <CardActions sx={{ justifyContent: 'flex-end', pr: 1.5, pb: 1.5 }}>
                        <Button color="secondary" variant="contained" size="medium" onClick={() => setRedirect({ trigger: true, route: `/reporte?id=${issue.id}` })}>
                          Mas Detalles
                        </Button>
                      </CardActions>
                    </Card>
                  )) 
                )
                )
                }
              </>
            ) : (
              <>
                {!loading && (
                  activeIssues.length === 0 ? (

                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 'calc(100vh - 150px)' }}>
                    <Card variant="outlined" sx={{ border: 0, backgroundColor: 'transparent' }} style={{ borderRadius: '10px', textAlign: 'center', width: '100%', paddingTop: '30%', paddingBottom: '40%' }}>
                      <CardContent>
                        <FeedbackIcon sx={{ fontSize: 80, color: 'text.secondary', mb: 2 }} />
                        <Typography variant="h5" component="div" sx={{ mb: 2 }}><b>No hay ningun reporte activo</b></Typography>
                        <Divider />
                        <Typography variant="body1" color="text.secondary" sx={{ mt: 2 }}>Aqui podras ver los reportes que esten activos.</Typography>
                      </CardContent>
                    </Card>
                  </Box>
                  ) : (
                    activeIssues.map((issue) => (
                      <Card
                        key={issue.id}
                        variant="outlined"
                        sx={{ mb: 1.2 }}
                        onClick={() => setRedirect({ trigger: true, route: `/reporte?id=${issue.id}` })}
                      >
                        <CardHeader
                          className="card-header"
                          style={{
                            // backgroundImage: `url(${amenity.imagen})`,
                            backgroundImage: `url(${apiUrl}/img/issue/${issue.id}.jpg)`,
                          }}
                        />
                        <CardContent sx={{ pb: 0 }}>
                          <Typography variant="body1" color="text.secondary" sx={{ textAlign: 'right', mt: -1.5}}>
                            Ticket {issue.id.toUpperCase()}
                          </Typography>
                          <Typography variant="h5" component="div">
                            <b>{issue.titulo}</b>
                          </Typography>
                          <Typography variant="body1" color="text.primary" sx={{ pb: 1 }}>
                            {issue.descripcion.length > 80 ? `${issue.descripcion.substring(0, 80)}...` : issue.descripcion}
                          </Typography>
                          <Typography variant="body1" color="text.secondary">
                            <ArrowCircleRightIcon style={{ fontSize: '1rem', verticalAlign: 'middle' }} /> <b>Estado:</b> {stateFriendlyNames[issue.estado.estado].nombre_largo}
                          </Typography>
                          <Typography variant="body1" color="text.secondary">
                            <PersonIcon style={{ fontSize: '1rem', verticalAlign: 'middle' }} /> <b>Reportado por:</b> {appConfig.titleCase(issue.creador.usuario_nombre)}
                          </Typography>
                          <Typography variant="body1" color="text.secondary">
                            <AccessTimeIcon style={{ fontSize: '1rem', verticalAlign: 'middle' }} /> <b>{stateFriendlyNames[issue.estado.estado].nombre_accion} {appConfig.formatFriendlyDate(issue.estado.fecha)}</b>
                          </Typography>
                        </CardContent>
                        <CardActions sx={{ justifyContent: 'flex-end', pr: 1.5, pb: 1.5 }}>
                          <Button color="secondary" variant="contained" size="medium" onClick={() => setRedirect({ trigger: true, route: `/reporte?id=${issue.id}` })}>
                            Mas Detalles
                          </Button>
                        </CardActions>
                      </Card>
                    ))
                  )
                )}
              </>
            )}
          </motion.div>
        </AnimatePresence>
    </Box>
    <Box sx={{ m: 1, display: 'flex', justifyContent: 'center' }}>
      <Fab
        sx={fabStyle}
        variant="extended"
        color="secondary"
        size="large"
        aria-label="add"
        onClick={() => setRedirect({ trigger: true, route: '/nuevoreporte' })}
      >
        <ReportIcon sx={{ mr: 1 }} />
        Nuevo Reporte
      </Fab>
    </Box>
    <BackgroundImage />
    </Container>
  );
}