import * as React from 'react';
import { Navigate } from 'react-router-dom';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import AuthService from '../services/auth.js';
import "../Amenities.css"

import BackgroundImage from '../components/Cityline';

import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import SendIcon from '@mui/icons-material/Send';
import Divider from '@mui/material/Divider';
import Checkbox from '@mui/material/Checkbox';


import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';

import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';


import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';

import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import PersonIcon from '@mui/icons-material/Person';
import CommentIcon from '@mui/icons-material/Comment';
import LocationOnIcon from '@mui/icons-material/LocationOn';

import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import TextField from '@mui/material/TextField';

// import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import dayjs from 'dayjs';
import Drawer from '@mui/material/Drawer';

import axios from 'axios';
import AppConfig from '../services/app-config';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';

import Resizer from 'react-image-file-resizer';
import Avatar from '@mui/material/Avatar'; // Add this import
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import ClearIcon from '@mui/icons-material/Clear';
import WarningIcon from '@mui/icons-material/Warning';
import DeleteIcon from '@mui/icons-material/Delete';

export default function Issue({setNavbarState, navbarState, window}) {
  const [redirect, setRedirect] = React.useState({ trigger: false, route: '/' });
  const [loading, setLoading] = React.useState(true);
  const [drawerShow, setDrawerShow] = React.useState(false);
  const [showBackdrop, setShowBackdrop] = React.useState(false);
  const [reservationDialog,setReservationDialog] = React.useState(false);
  const [snackbar, setSnackbar] = React.useState({ open: false, message: '', severity: 'success' });
  const [refresh,setRefresh] = React.useState(false);
  const [issue,setIssue] = React.useState({});
  const [issueComment,setIssueComment] = React.useState("");
  const [issueImage, setIssueImage] = React.useState(null);
  const [issueImagePreview, setIssueImagePreview] = React.useState(null);
  const [fullImageDialog, setFullImageDialog] = React.useState({ open: false, src: '' });
  const [confirmDialog, setConfirmDialog] = React.useState({ open: false, action: null });
  const [deleteCommentDialog, setDeleteCommentDialog] = React.useState({ open: false, commentDate: null });

  const issueId = new URLSearchParams(location.search).get('id');
  const apiUrl = process.env.REACT_APP_MGO_API_URL;

  const stateFriendlyNames = AppConfig.getLocal("issues")

  const userCors = process.env.REACT_APP_USE_CORS;
  if ( userCors === "true") {
    axios.defaults.withCredentials = true
  }

  const closeReservationDialog = () => {
    setReservationDialog(false);
  }

  const triggerRefresh = () => {
    setRefresh(!refresh);
  }

  const updateIssue = (estado) => {
    if (estado === 'cancelado') {
      setConfirmDialog({ open: true, action: () => performUpdateIssue(estado) });
    } else {
      performUpdateIssue(estado);
    }
  }

  const performUpdateIssue = (estado) => {
    setLoading(true);
    setShowBackdrop(true);
    axios.post(`${apiUrl}/issue/update/${issueId}`, {estado: estado})
      .then(response => {
        AppConfig.parseResponse(response);
        if (response.status === 200) {
          setSnackbar({ open: true, message: "Estado actualizado correctamente", severity: 'success' });
          fetchInfo(issueId);
        } else if (response.status === 422) {
          setSnackbar({ open: true, message: response.data.data.mensaje, severity: 'error' });
        }
        setLoading(false);
        setShowBackdrop(false);
      })
      .catch(error => {
        if(error.response && error.response.data && error.response.data.code === 422) {
          setSnackbar({ open: true, message: error.response.data.data.mensaje, severity: 'error' });
        }
        else  {
        console.error('Error fetching issues:', error);
        setSnackbar({ open: true, message: "Hubo un error al intentar actualizar el estado del ticket, porfavor intente mas tarde.", severity: 'error' })};
        setLoading(false);
        setShowBackdrop(false);
      });
  }

  const toggleSubscription = (e) => {
    setShowBackdrop(true);
    let subscribed_text = e.target.checked ? "true" : "false";
    axios.post(`${apiUrl}/issues/subscribe/${issueId}/${subscribed_text}`)
    .then(response => {
      AppConfig.parseResponse(response);
      if (response.status === 200) {
        setIssue({...issue, subscribed: response.data.data.enabled});
        setSnackbar({ open: true, message: "Notificaciones actualizadas correctamente", severity: 'success' });
      }
      setShowBackdrop(false);
    })
    .catch(error => {
      console.error('Error toggling subscription:', error);
      setSnackbar({ open: true, message: "Hubo un error al intentar actualizar las notificaciones, porfavor intente mas tarde.", severity: 'error' });
      setShowBackdrop(false);
    });
  }

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        1200,
        1200,
        'JPEG',
        80,
        0,
        (uri) => {
          resolve(uri);
        },
        'base64',
        500,
        500
      );
    });

  const handleImageAttach = async (event) => {
    try {
      const file = event.target.files[0];
      const image = await resizeFile(file);
      setIssueImage(image);
      setIssueImagePreview(URL.createObjectURL(file));
    } catch (err) {
      console.log(err);
      setSnackbar({ open: true, message: "Error al procesar la imagen", severity: 'error' });
    }
  };

  const commentIssue = () => {
    setShowBackdrop(true);
    if (issueComment === "") {
      setSnackbar({ open: true, message: "No puedes enviar un comentario vacío, si estas agregando una imagen, porfavor agrega una descripcion.", severity: 'error' });
      setShowBackdrop(false);
      return;
    }
    let data = {
      comentario: issueComment
    }
    if (issueImage) {
      data.imagen = issueImage;
    }
    console.log(data);
    axios.post(`${apiUrl}/issue/comment/${issueId}`, data)
    .then(response => {
      setIssueComment("");
      handleRemoveImage();
      AppConfig.parseResponse(response);
      if (response.status === 200) {
        setSnackbar({ open: true, message: "Comentario agregado", severity: 'success' });
        fetchInfo(issueId);
      } else if (response.status === 422) {
        setSnackbar({ open: true, message: response.data.data.mensaje, severity: 'error' });
      }
      setShowBackdrop(false);
    })
    .catch(error => {
      if(error.response && error.response.data && error.response.data.code === 422) {
        setSnackbar({ open: true, message: error.response.data.data.mensaje, severity: 'error' });
      }
      else  {
      console.error('Error commenting:', error);
      setSnackbar({ open: true, message: "Hubo un error al intentar agregar tu comentario, porfavor intente mas tarde.", severity: 'error' })};
      setShowBackdrop(false);
    });
  }

  const handleDrawerToggle = () => {
    setDrawerShow(!drawerShow);
  }

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  const ceil = (option, dayjsClass) => {
    dayjsClass.prototype.ceil = function (unit, amount) {
        return this.add(amount - (this.get(unit) % amount), unit).startOf(unit);
    };
  };
  dayjs.extend(ceil);


  const fetchInfo = (id) => {

    axios.get(`${apiUrl}/issue/get/${id}`)
      .then(response => {
        AppConfig.parseResponse(response);
        if (response.status === 200) {
          const issueData = response.data.data;
          const lastEstado = issueData.estados[issueData.estados.length - 1];
          issueData.estado = lastEstado;
          setIssue(issueData);
          setLoading(false);
          setShowBackdrop(false);
        }
      })
      .catch(error => {
        setRedirect({ trigger: true, route: '/reportes' });
        console.error('Error fetching issues:', error);
        setSnackbar({ open: true, message: 'Hubo un error obteniendo los detalles del reporte', severity: 'error' });
        setShowBackdrop(false);
      });
  }

  const fabStyle = {
    position: 'fixed',
    bottom: 32,
    right: 32
  };

  const reservationDrawer = (
    <Card
    sx={{ py: 3, px: 2, display: 'block', marginLeft: 'auto', marginRight: 'auto', borderTopLeftRadius: "20px", borderTopRightRadius: "20px", borderBottomRightRadius: "0px", borderBottomLeftRadius: "0px", maxWidth: "550px", width: "100%" }}>
        
      <CardContent>
      </CardContent>
      <CardActions>
        <Button size="medium" color="inherit" variant="outlined" onClick={handleDrawerToggle}>Cancelar</Button>
        <Button size="medium" color="secondary" variant="contained" >Reservar</Button>
      </CardActions>
    </Card>
  );

  const handleRemoveImage = () => {
    setIssueImage(null);
    setIssueImagePreview(null);
  };

  const handleOpenFullImage = (src) => {
    setFullImageDialog({ open: true, src });
  };

  const handleCloseFullImage = () => {
    setFullImageDialog({ open: false, src: '' });
  };

  const handleCloseConfirmDialog = () => {
    setConfirmDialog({ open: false, action: null });
  };

  const handleOpenDeleteDialog = (commentDate) => {
    setDeleteCommentDialog({ open: true, commentDate });
  };

  const handleCloseDeleteDialog = () => {
    setDeleteCommentDialog({ open: false, commentDate: null });
  };

  const handleDeleteComment = () => {
    setShowBackdrop(true);
    axios.post(`${apiUrl}/issue/delete/comment/${issueId}`, { comment_id: deleteCommentDialog.commentDate })
      .then(response => {
        AppConfig.parseResponse(response);
        if (response.status === 200) {
          setSnackbar({ open: true, message: "Comentario eliminado correctamente", severity: 'success' });
          fetchInfo(issueId);
        } else {
          setSnackbar({ open: true, message: "Error al eliminar el comentario", severity: 'error' });
        }
        setShowBackdrop(false);
        handleCloseDeleteDialog();
      })
      .catch(error => {
        console.error('Error deleting comment:', error);
        setSnackbar({ open: true, message: "Hubo un error al intentar eliminar el comentario", severity: 'error' });
        setShowBackdrop(false);
        handleCloseDeleteDialog();
      });
  };

  React.useEffect(() => {
    setNavbarState({...navbarState, showBack: true, showOptions: false, showSearch: false})
    if (issueId === null) {
      setRedirect({ trigger: true, route: '/reportes' });
    }
    setShowBackdrop(true);
    setLoading(true);
    fetchInfo(issueId);
  }, [refresh]);

const container = window !== undefined ? () => window().document.body : undefined;
return (
    <>
    <Container maxWidth="sm">
      {redirect.trigger && (
        <Navigate to={redirect.route} replace={true} />
      )}
    <Snackbar open={snackbar.open} autoHideDuration={3000} anchorOrigin={{ vertical: "top", horizontal: "center" }} sx={{mt: "3.2rem"}} onClose={handleSnackbarClose}>
      <Alert onClose={handleSnackbarClose} severity={snackbar.severity}>
        {snackbar.message}
      </Alert>
    </Snackbar>
    <Box sx={{ mt: 1 }}>
      <Backdrop open={showBackdrop} sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, color: '#fff' }}>
        <CircularProgress color="inherit" />
      </Backdrop>
        {!loading && (
          <>
         <img src={`${apiUrl}/img/issue/${issueId}.jpg`} alt="Issue Image" style={{ width: '100%', borderTopLeftRadius: '15px', borderTopRightRadius: '15px', paddingLeft: 0}} />
          <Card
            key={issueId}
            variant="outlined"
            sx={{ mt: -0.6, borderTopLeftRadius: "0px", borderTopRightRadius: "0px" }}
          >
            <CardContent>
              <Typography variant="body1" color="text.secondary" sx={{ textAlign: 'right', mt: -1.5 }}>
                Ticket {issueId.toUpperCase()}
              </Typography>
              <Typography variant="h5" component="div" sx={{mb: 1}}>
                <b>{issue.titulo}</b>
              </Typography>
              <Typography variant="body1" color="text.primary" sx={{mb: 1.5}}>
                {issue.descripcion} 
              </Typography>
              
              <Typography variant="body1" color="text.secondary" sx={{mb: 0.2}}>
                <LocationOnIcon style={{ fontSize: '1rem', verticalAlign: 'middle' }} /> <b>Area:</b> {AppConfig.titleCase(issue.area)}
              </Typography>
              <Typography variant="body1" color="text.secondary" sx={{mb: 0.2}}>
                <PersonIcon style={{ fontSize: '1rem', verticalAlign: 'middle' }} /> <b>Reportado por:</b> {AppConfig.titleCase(issue.creador.usuario_nombre)}
              </Typography>
              <Typography variant="body1" color="text.secondary" sx={{mb: 0.2}}>
                <ArrowCircleRightIcon style={{ fontSize: '1rem', verticalAlign: 'middle' }} /> <b>Estado:</b> {stateFriendlyNames[issue.estado.estado].nombre_largo}
              </Typography>
              {AppConfig.getLocal("notification_enable") && issue.estado.estado !== 'cancelado' && issue.estado.estado !== 'resuelto' && <Box sx={{ display: 'flex', alignItems: 'center', mt: 1, mb: 1 }}>
                <Checkbox
                  checked={issue.subscribed}
                  onChange={toggleSubscription}
                />
                <Typography variant="body2" color="text.secondary">
                  Recibir notificaciones sobre este reporte
                </Typography>
              </Box>}
              <Divider sx={{ mt: 1, mb: 2 }} />
              <Typography variant="h6" component="div" sx={{mt: 1}}>
                Actualizaciones
              </Typography>
              <Timeline position="right">
                {issue.estados.map((estado, index) => (
                  <TimelineItem key={`estado-${index}`}>
                    <TimelineOppositeContent>
                      <Typography variant="body2" color="text.secondary">
                        {AppConfig.formatFriendlyDate(estado.fecha)}
                      </Typography>
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                      <TimelineDot color="primary" />
                      {index !== issue.estados.length - 1 && <TimelineConnector />}
                    </TimelineSeparator>
                    <TimelineContent>
                      <Typography variant="body1" color="text.primary">
                        {stateFriendlyNames[estado.estado].nombre_corto}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        <PersonIcon style={{ fontSize: '1rem', verticalAlign: 'middle' }} /> {estado.usuario_nombre}
                      </Typography>
                    </TimelineContent>
                  </TimelineItem>
                ))}
              </Timeline>
              { (AuthService.userRoleIncludes(["admin"])) && stateFriendlyNames[issue.estado.estado].siguiente_estado.map((estado, index) => (
                <Button
                  key={`estado-${index}`}
                  variant={estado === 'cancelado' ? "text" : "contained"}
                  color={estado === 'cancelado' ? "inherit" : "secondary"}
                  fullWidth
                  sx={{ mb: 0.1 }}
                  onClick={() => updateIssue(estado)}
                >
                  {estado === 'cancelado' ? 'Cancelar Ticket' : `Actualizar a ${stateFriendlyNames[estado].nombre_corto}`}
                </Button>
              ))
              }
              {AuthService.userRoleIncludes(["admin"]) && (issue.estado.estado === 'cancelado' || issue.estado.estado === 'resuelto') && <Button
                  key="reabrir"
                  variant="text"
                  color="secondary"
                  fullWidth
                  sx={{ mb: 0.1 }}
                  onClick={() => updateIssue('en_progreso')}
                >
                  Reabrir Ticket
                </Button>
              }
              <Divider sx={{ mt: 2, mb: 2 }} />
              <Typography variant="h6" component="div" sx={{mt: 1}}>
                Comentarios
              </Typography>
                  {issue.comentarios.length === 0 && (
                    <Typography variant="body1" color="text.secondary" sx={{ mb: 1.5, mt: 1.5, textAlign: 'center' }}>
                      No hay ningún comentario
                    </Typography>
                  )}
                  {issue.comentarios.map((comentario, index) => (
                    <Paper key={`comentario-${index}`} sx={{ mt: 1 }}>
                      <Card variant="outlined">
                        <CardContent>
                          <Box display="flex" justifyContent="space-between" alignItems="flex-start">
                            <CommentIcon sx={{mr: 1}}/>
                            {AuthService.getCurrentUserEmail() === comentario.usuario && (
                              <IconButton
                                size="small"
                                onClick={() => handleOpenDeleteDialog(comentario.fecha)}
                                sx={{ p: 0 }}
                              >
                                <DeleteIcon fontSize="small" />
                              </IconButton>
                            )}
                          </Box>
                          <Typography variant="body1" color="text.primary" sx={{mt: 1}}>
                            {comentario.comentario}
                          </Typography>
                          {comentario.imagen_adjunta && (
                            <Box sx={{ my: 0.5, cursor: 'pointer', display: 'flex', justifyContent: 'flex-end' }}>
                              <img 
                                src={`${apiUrl}/img/issue/${issueId}_${comentario.fecha}_cmnt.jpg`} 
                                alt="Comment Image" 
                                style={{ 
                                  width: 'auto', 
                                  height: '75px', 
                                  objectFit: 'cover',
                                  borderRadius: '8px'
                                }}
                                onClick={() => handleOpenFullImage(`${apiUrl}/img/issue/${issueId}_${comentario.fecha}_cmnt.jpg`)}
                              />
                            </Box>
                          )}
                          <Typography variant="body2" color="text.secondary" sx={{ textAlign: 'right' }}>
                            {comentario.usuario_nombre} - {AppConfig.formatFriendlyDate(comentario.fecha)}
                          </Typography>
                        </CardContent>
                      </Card>
                    </Paper>
                  ))}

                {issue.estado.estado !== 'cancelado' && issue.estado.estado !== 'resuelto' && (
                  <Box sx={{ mt: 2, border: 1, borderColor: "divider", borderRadius: 2, p: 2 }}>
                    <TextField
                      id="comentario-input"
                      label="Escribe tu Comentario"
                      variant="filled"
                      fullWidth
                      multiline
                      rows={2}
                      onChange={(event) => setIssueComment(event.target.value)}
                      value={issueComment}
                      sx={{
                        '& .MuiFilledInput-root': {
                          backgroundColor: 'transparent',
                        },
                      }}
                    />
                    <Box sx={{ mt: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <input
                          accept="image/*"
                          style={{ display: 'none' }}
                          id="image-file"
                          type="file"
                          onChange={handleImageAttach}
                        />
                        {!issueImagePreview && <label htmlFor="image-file">
                          <IconButton color="secondary" component="span">
                            {/* {issueImagePreview ? <ChangeCircleIcon /> : <AddPhotoAlternateIcon />} */}
                            <AddPhotoAlternateIcon />
                          </IconButton>
                        </label>}
                        {issueImagePreview && (
                          <>
                            <Avatar
                              src={issueImagePreview}
                              alt="Attached Image"
                              sx={{ width: 45, height: 45, ml: 1 }}
                            />
                            <Button
                              size="small"
                              onClick={handleRemoveImage}
                              startIcon={<ClearIcon />}
                              sx={{ ml: 1, minWidth: 'auto', p: 0.5 }}
                            >
                              Quitar
                            </Button>
                          </>
                        )}
                      </Box>
                      <Button
                        variant="text"
                        color="secondary"
                        endIcon={<SendIcon />}
                        onClick={commentIssue}
                      >
                        Enviar
                      </Button>
                    </Box>
                  </Box>
                )}

            </CardContent>
            <CardActions >
            </CardActions>
          </Card>
          </>)}
    </Box>

    {!loading && (
    <>
    <Drawer
          container={container}
          anchor="bottom"
          variant="temporary"
          open={drawerShow}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          PaperProps={{ elevation: 0, style: { backgroundColor: "transparent" } }}
          sx={{
            display: { xs: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: '440' },
          }}
        >
          {reservationDrawer}
    </Drawer>
    <Dialog open={reservationDialog} onClose={closeReservationDialog}>
        <DialogTitle>Confirmar Reserva</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {reservationDialog ? <Typography>¿Está seguro que desea reservar esta amenidad el dia {amenity.dia_de_semana}, {date.format('DD')} de {amenity.mes} de {timeRanges.from.value.format('HH:mm')} a {timeRanges.to.value.format('HH:mm')} ?</Typography> : ''}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeReservationDialog} color="primary">
            Cancelar
          </Button>
          <Button color="secondary">
            Confirmar
          </Button>
        </DialogActions>
    </Dialog>
    </>
    )}
      
    <BackgroundImage />
    </Container>
    <Dialog
      open={fullImageDialog.open}
      onClose={handleCloseFullImage}
      maxWidth="xl"
      fullWidth
    >
      <DialogContent>
        <IconButton
          aria-label="close"
          onClick={handleCloseFullImage}
          sx={{
            position: 'absolute',
            right: 2,
            top: 2
          }}
        >
          <ClearIcon />
        </IconButton>
        <img 
          src={fullImageDialog.src} 
          alt="Full size comment image" 
          style={{ width: '100%', height: 'auto' }}
        />
      </DialogContent>
    </Dialog>
    <Dialog
      open={confirmDialog.open}
      onClose={handleCloseConfirmDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {"¿Estás seguro de que quieres cancelar este ticket?"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <Box display="flex" alignItems="center">
            <WarningIcon color="warning" sx={{ mr: 1 }} />
            El ticket se podra reabrir pero quedara el estado en el historial.
          </Box>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseConfirmDialog} color="primary">
          No, mantener abierto
        </Button>
        <Button 
          onClick={() => {
            confirmDialog.action();
            handleCloseConfirmDialog();
          }} 
          color="secondary" 
          variant="contained"
          autoFocus
        >
          Sí, cancelar ticket
        </Button>
      </DialogActions>
    </Dialog>
    <Dialog
      open={deleteCommentDialog.open}
      onClose={handleCloseDeleteDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {"¿Estás seguro de que quieres eliminar este comentario?"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Esta acción no se puede deshacer. El comentario será eliminado permanentemente.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseDeleteDialog} color="primary">
          Cancelar
        </Button>
        <Button 
          onClick={handleDeleteComment}
          color="secondary" 
          variant="contained"
          autoFocus
        >
          Eliminar
        </Button>
      </DialogActions>
    </Dialog>
  </>);
}